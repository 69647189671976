import { render, staticRenderFns } from "./project-detail.html?vue&type=template&id=f4a6f910&scoped=true&"
import script from "./project-detail.js?vue&type=script&lang=js&"
export * from "./project-detail.js?vue&type=script&lang=js&"
import style0 from "./project-detail.scss?vue&type=style&index=0&id=f4a6f910&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4a6f910",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VRow})
